import axios from "axios";

const BE_ENDOINT = "https://chatbot-backend-1k04.onrender.com/";

export const apiCall = async (
  path: string,
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  _params: any,
  body: any,
  _query: any
) => {
  const config = {
    method,
    url: `${BE_ENDOINT}${path}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
  };
  try {
    return (await axios.request(config)).data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
