import React from "react";
import {
  Loading,
  Error,
  UserItem,
  Card,
  CardBody,
  CardHeader,
} from "../components";
import useAxios from "../hooks/useAxios";
import { User } from "../types";

const Chats = (): JSX.Element => {
  const data: User[] = [
    {
      id: 1,
      image: "https://robohash.org/hicveldicta.png",
      title: "First Chat",
    },
  ];
  return (
    <Card>
      <CardHeader>
        <h1 className="text-lg">Chats</h1>
      </CardHeader>
      <CardBody>
        <ul className="list-none">
          {data.map((user) => (
            <UserItem user={user} key={user.id} />
          ))}
        </ul>
      </CardBody>
    </Card>
  );
};

export default Chats;
