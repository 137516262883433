import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { apiCall } from "../apis";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderBackButton,
  Error,
  Loading,
  MessageInput,
  MessageItem,
} from "../components";
import useAxios from "../hooks/useAxios";
import { Message, User } from "../types";

const Chat = (): JSX.Element => {
  const { id } = useParams();
  const state: any = useLocation().state;
  const user: User = state.user as User;
  const [messages, setMessages] = useState<Message[]>([]);
  const navigate = useNavigate();
  const [queryLoading, setQueryLoading] = useState<boolean>(false);
  const emptyDivRef = useRef<HTMLDivElement>();
  // sample id for new messages (to support key for loop)
  const sampleId = useRef(1);

  useEffect(() => {
    // Get and set mock messages from 'mock-messages.js'
    setMessages([
      {
        id: 1,
        sent: false,
        text: "Hello!",
      },
    ]);
  }, []);

  const getAnswer = async (message: string) => {
    setQueryLoading(true);
    const body = {
      query: message,
    };
    const res = await apiCall("query", "POST", null, body, null);
    setMessages((prevState) => [
      ...prevState,
      {
        id: (sampleId.current += 1),
        sent: false,
        text: res.answer,
      },
    ]);
    setQueryLoading(false);
  };

  const sendMessage = useCallback(
    async (message: string) => {
      setMessages((prevState) => [
        ...prevState,
        {
          id: (sampleId.current += 1),
          sent: true,
          text: message,
        },
      ]);
      await getAnswer(message);
    },
    [messages]
  );

  const scrollToBottom = useCallback(() => {
    emptyDivRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const setEmptyDivRef = useCallback((ref: HTMLDivElement) => {
    emptyDivRef.current = ref;
    scrollToBottom();
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardHeaderBackButton onBack={() => navigate(-1)} />
        <Link
          to={`/${user.id}/profile`}
          className="flex flex-row text-blue-600"
        >
          <img
            src={user.image}
            alt={user.title}
            className="w-7 h-7 bg-indigo-300 rounded-full mr-3"
          />
          <h1 className="text-lg">{user.title}</h1>
        </Link>
      </CardHeader>
      <CardBody>
        <ul className="space-y-3 p-5">
          {messages.map((item) => (
            <MessageItem key={item.id} message={item} />
          ))}
          {queryLoading && (
            <MessageItem
              key={"loading"}
              message={{ id: 1000, sent: false, text: "Generating..." }}
            />
          )}
        </ul>
        <div ref={setEmptyDivRef} />
      </CardBody>
      <MessageInput onSubmit={sendMessage} />
    </Card>
  );
};

export default Chat;
